
import { Component, Vue, Prop } from "vue-property-decorator";
import PageTitleBar from "@/components/PageTitleBar.vue";
import BreadCrumbs from "@/components/BreadCrumbs.vue";
import VsPagination from "@vuesimple/vs-pagination";
import PostCard from "@/components/PostCard.vue";

import { BrainLibraryAttachmentDTO } from "@/interfaces/BrainLibrary.dto";
import { getQuarter } from "@/utils/Functions";

type tabType = {
  id: number;
  title: string;
  subtype: string | undefined;
  content: GroupedBrainLibraryYearMonthDTO[];
};

type paramsType = {
  page: number;
  libraryType?: string;
  librarySubtype?: string;
};

export interface BrainLibraryYearMonthDTO {
  _id: string;
  title: string;
  description: string;
  scheduleDate: string;
  type: string;
  subtype: string;
  year: string;
  mounth: string;
  groupByValue: string;
  brainLibraryAttachments?: Array<BrainLibraryAttachmentDTO>;
}

export interface GroupedBrainLibraryYearMonthDTO {
  month: string;
  year: string;
  items: Array<BrainLibraryYearMonthDTO>;
}

@Component({
  components: { PageTitleBar, BreadCrumbs, PostCard, VsPagination },
})
export default class AlternativeInvestments extends Vue {
  @Prop() groupedItems!: GroupedBrainLibraryYearMonthDTO[];
  @Prop() currentPagePagination!: number;

  show = 0;

  tabs: tabType[] = [
    {
      id: 0,
      title: "REAL ESTATE",
      subtype: "REAL_ESTATE",
      content: [],
    },
    {
      id: 1,
      title: "PRIVATE DEBT",
      subtype: "PRIVATE_DEBT",
      content: [],
    },
    {
      id: 2,
      title: "SPECIALTY FINANCE",
      subtype: "SPECIALTY_FINANCE",
      content: [],
    },
    {
      id: 3,
      title: "PRIVATE EQUITY",
      subtype: "PRIVATE_EQUITY",
      content: [],
    },
    {
      id: 4,
      title: "VENTURE CAPITAL",
      subtype: "VENTURE_CAPITAL",
      content: [],
    },
  ];

  mounted(): void {
    this.refresh();
  }

  async refresh(): Promise<void> {
    try {
      this.tabs.forEach((tab) => (tab.content = []));
      const tab = this.tabs.find((tab) => tab.id === this.show);

      const params: paramsType = {
        page: this.currentPagePagination,
        librarySubtype: tab?.subtype,
      };

      const { data } = await this.$api.get("/library/library-access", {
        params,
      });

      this.$emit("createItemsWhitYearAndMonth", data.records);
      this.$emit("changeTotalPage", data.pages || 1);
      setTimeout(() => this.tabContent(), 10);
    } catch (error) {
      console.error(error);
    }
  }

  tabContent(): void {
    this.groupedItems.forEach((group) => {
      group.items.forEach((item) => {
        this.tabs.forEach((tab) => {
          if (tab.subtype === item.subtype) {
            const index = tab.content.findIndex((content) => {
              const quarter = getQuarter(group.month);
              return quarter === content.month && group.year === content.year;
            });

            if (index === -1) {
              tab.content.push({
                month: getQuarter(group.month),
                year: group.year,
                items: [item],
              });
            } else {
              tab.content[index].items.push(item);
            }
          }
        });
      });
    });
  }

  navigate(id: number): void {
    if (id === this.show) return;
    this.$emit("resetPage");

    setTimeout(() => {
      this.show = id;
      this.refresh();
    }, 10);
  }
}
